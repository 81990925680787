import PoppinsRegular from './Poppins-Regular.woff'
import PoppinsMedium from './Poppins-Medium.woff'
import PoppinsBold from './Poppins-Bold.woff' // Changed file extension to woff
import PoppinsBlack from './Poppins-Black.woff' // Changed file extension to woff
import PoppinsLight from './Poppins-Light.woff'

const poppinsRegular = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: '400',
  src: `url(${PoppinsRegular}) format('woff')`, // Corrected file format
}

const poppinsMedium = {
  fontFamily: 'Poppins',
  fontStyle: 'italic', // Italic is typically not used for medium weight
  fontWeight: '500', // Medium weight is usually 500
  src: `url(${PoppinsMedium}) format('woff')`, // Corrected file format
}

const poppinsBold = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: '700',
  src: `url(${PoppinsBold}) format('woff')`, // Corrected file format
}

const poppinsBlack = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: '900',
  src: `url(${PoppinsBlack}) format('woff')`, // Corrected file format
}

const poppinsLight = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: '300', // Light weight is typically 300
  src: `url(${PoppinsLight}) format('woff')`, // Corrected file format
}

export default [poppinsRegular, poppinsMedium, poppinsBold, poppinsBlack, poppinsLight]
