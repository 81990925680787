import errorCardBg from './images/error-card.jpg'
import authDesktopBg from './images/auth-desktop-bg.jpg'
import authTravelDsBg from './images/travel-desktop-bg.jpg'
import reviewTravelDsBg from './images/review-travel-desktop-bg.jpg'
import reviewTravelMobBg from './images/review-travel-mobile-bg.jpg'
import doneContentMobBg from './images/content-done-mobile-bg.jpg'
import authContentMobBg from './images/content-auth-mobile-bg.jpg'
import authTravelMobileBg from './images/travel-mobile-bg.jpg'
import contentCardRightSide from './images/content-card-right-side.jpg'
import doneDesctopBg from './images/done-desktop-bg.jpg'
import loadingDesktopBg from './images/loading-desktop-bg.jpg'
import reviewContentDesktopBg from './images/reviewCardContent.jpg'
import CustomFontFaces from './fonts/Poppins/font-face.declaration'

import {
  ThemeBrandAssetsType,
  ThemeBrandColorsType,
  ThemeBrandTypographyType,
} from 'BrandTheme/types'
import brandTheme from '../theme/utils'
import { getProductName } from '@dg-util'
import logoMin from './images/logoMin.png'
import { ProductName } from '../../types/interface/quote.interface'

const initCallback = () => {
  const productName: ProductName = getProductName()

  const brandColors: ThemeBrandColorsType = {
    main: '#0E2B49',
    secondary: '#4B729B',
    background: '#F6FAFD',
    headingMain: '#0E2B49',
    headingSecondary: '#0E2B49',
    textMain: '#0E2B49',
    textSecondary: '#4B729B',
    textError: '#E65B60',
    textPlaceholder: '#768692',
    textDisclaimer: '#627F9E',
    textInactive: '#C8CFD3',
    buttonActive: '#0E2B49',
    buttonInactive: '#98A29E',
    inputActive: '#F1F0EB',
    inputInactive: '#F1F0EB',
    linkMain: '#0E2B49',
    linkSecondary: '#0E2B49',
    modal: '#C4C4C4',
    shadow: '#000000',
    clean: '#FFFFFF',
    cardHeader: '#0E2B49',
    textCardHeader: '#FFF',
    textCardFooter: '#0E2B49',
  }

  const brandTypography: ThemeBrandTypographyType = {
    heading: {
      h1: {
        fontSize: '1.75rem', // 28px
        fontFamily: 'Poppins',
        fontWeight: 700,
        color: brandColors.headingMain,
      },
      h2: {
        fontSize: '1.5rem', // 24px
        fontFamily: 'Poppins',
        fontWeight: 700,
        color: brandColors.headingMain,
      },
      h3: {
        fontSize: '1.25rem', // 20px
        fontFamily: 'Poppins',
        fontWeight: 700,
        color: brandColors.headingSecondary,
      },
      h4: {
        fontSize: '1rem', // 16px
        fontFamily: 'Poppins',
        fontWeight: 400,
        color: brandColors.headingMain,
      },
      h5: {
        fontSize: '0.75rem', // 12px
        fontFamily: 'Poppins',
        fontWeight: 400,
        color: brandColors.textMain,
      },
      h6: {
        fontSize: '0.75rem', // 12px
        fontFamily: 'Poppins',
        fontWeight: 400,
        color: brandColors.headingMain,
      },
    },
    text: {
      xSmall: {
        fontSize: '0.688rem', // 11px
        lineHeight: 1.4,
        fontFamily: 'Poppins',
      },
      small: {
        fontSize: '0.75rem', // 12px
        lineHeight: 1.4,
        fontFamily: 'Poppins',
      },
      normal: {
        fontSize: '1rem', // 16px
        lineHeight: 1.4,
        fontFamily: 'Poppins',
      },
      large: {
        fontSize: '1.5rem', // 24px
        lineHeight: 1.4,
        fontFamily: 'Poppins',
      },
      xLarge: {
        fontSize: '2.5rem', // 40px
        lineHeight: 1.4,
        fontFamily: 'Poppins',
      },
    },
    modifiers: {
      bold: {
        fontWeight: 700,
      },
      decorated: {
        textDecoration: 'underline',
      },
      unDecorated: {
        textDecoration: 'none',
        '& *': {
          textDecoration: 'none',
        },
      },
      withHover: {
        '&:hover': {
          opacity: 0.75,
          cursor: 'pointer',
        },
      },
      preFormatted: {
        whiteSpace: 'pre',
      },
    },
    overrides: {
      authSubtitle: {
        width: 387,
      },
      ownerRadioOverride: {
        fontWeight: 700,
      },
      contactCustomerSwitchText: {
        color: brandColors.textSecondary,
      },
      contactCustomerSwitchSwitch: {
        color: brandColors.textSecondary,
        fontWeight: 700,
        padding: 0,
      },
    },
    extraFonts: ['Poppins', 'Poppins Black', 'Poppins Light'],
    customFontFaces: CustomFontFaces,
  }

  const theme = brandTheme.setup({
    productName,
    colors: brandColors,
    typography: brandTypography,
  }).theme

  const styleProductMap = {
    vehicle: {
      LMBgHeaderHeader: {
        backgroundPositionY: 'bottom',
        height: 300,
      },
    },
    travel: {
      LMBgHeaderHeader: {
        backgroundPositionY: '50px',
        height: 290,
      },
    },
    content: {
      LMBgHeaderHeader: {
        backgroundPositionY: '50px',
        height: 300,
      },
    },
  }

  const productBackgroundsMap = {
    vehicle: {
      loadingMobile: {
        background: `url(${loadingDesktopBg}) no-repeat center bottom`,
        backgroundSize: 'cover',
      },
      loading: {
        alignContent: 'center',
        background: `url(${loadingDesktopBg}) no-repeat center center fixed`,
        backgroundSize: 'cover',
      },
      authDesktop: {
        background: '',
      },
      authMobileBg: {
        background: `url(${authDesktopBg}) no-repeat left top scroll`,
        backgroundSize: 'cover',
        backgroundPosition: 'top',
      },
      reviewBg: {
        background: '',
      },
      mainCard: {
        background: `url(${authDesktopBg}) center / cover`,
        backgroundSize: 'cover',
      },
      secondaryCard: {
        background: `url(${doneDesctopBg}) no-repeat center center`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      },
      secondaryMobile: {
        background: `url(${doneDesctopBg}) no-repeat top scroll`,
        backgroundSize: 'contain',
        backgroundPosition: 'center 50px !important',
      },
    },
    travel: {
      loadingMobile: {
        background: `url(${loadingDesktopBg}) no-repeat center bottom`,
        backgroundSize: 'cover',
      },
      loading: {
        alignContent: 'center',
        background: `url(${loadingDesktopBg}) no-repeat center center fixed`,
        backgroundSize: 'cover',
      },
      authDesktop: {
        background: '',
      },
      authMobileBg: {
        background: `url(${authTravelMobileBg}) center 20px / cover no-repeat scroll !important`,
        backgroundSize: 'cover',
        backgroundPosition: 'top',
      },
      reviewBg: {
        backgroundColor: brandColors.clean,
        background: `url(${reviewTravelDsBg}) no-repeat right scroll`,
        backgroundSize: '50%',
        backgroundPositionY: '70px',
        backgroundRepeat: 'no-repeat',
      },
      mainCard: {
        background: `url(${authTravelDsBg}) center / cover`,
        backgroundSize: 'cover',
      },
      secondaryCard: {
        background: `url(${reviewTravelDsBg}) no-repeat center center`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      },
      secondaryMobile: {
        background: `url(${reviewTravelMobBg}) no-repeat top scroll`,
        backgroundSize: 'contain',
        backgroundPosition: 'center 50px !important',
      },
    },
    content: {
      loadingMobile: {
        background: `url(${loadingDesktopBg}) no-repeat center bottom`,
        backgroundSize: 'cover',
      },
      loading: {
        alignContent: 'center',
        background: `url(${loadingDesktopBg}) no-repeat center center fixed`,
        backgroundSize: 'cover',
      },
      authDesktop: {
        background: '',
      },
      authMobileBg: {
        background: `url(${authContentMobBg}) no-repeat center 60px scroll`,
        backgroundSize: 'contain',
        backgroundPosition: 'top',
      },
      reviewBg: {
        backgroundColor: brandColors.clean,
        background: `url(${reviewContentDesktopBg}) no-repeat right 80px scroll`,
        backgroundSize: '50%',
        backgroundRepeat: 'no-repeat',
      },
      mainCard: {
        background: `url(${authContentMobBg}) no-repeat right top scroll`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      },
      secondaryCard: {
        background: `url(${doneContentMobBg}) no-repeat center center`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      },
      secondaryMobile: {
        background: `url(${doneContentMobBg}) no-repeat top scroll`,
        backgroundSize: 'contain',
        backgroundPosition: 'center 50px !important',
      },
    },
    fnol_vehicle: {
      loadingMobile: {
        background: `url(${loadingDesktopBg}) no-repeat center bottom`,
        backgroundSize: 'cover',
      },
      loading: {
        alignContent: 'center',
        background: `url(${loadingDesktopBg}) no-repeat center center fixed`,
        backgroundSize: 'cover',
      },
      authDesktop: {
        background: '',
      },
      authMobileBg: {
        background: `url(${authDesktopBg}) no-repeat left top scroll`,
        backgroundSize: 'cover',
        backgroundPosition: 'top',
      },
      reviewBg: {
        background: '',
      },
      mainCard: {
        background: ``,
      },
    },
  }

  const overrides = {
    contained: {
      '&:hover': {
        color: brandColors.clean,
        opacity: 0.75,
        '& .MuiSvgIcon-root': {
          color: brandColors.clean,
        },
      },
      '&:disabled': {
        backgroundColor: brandColors.buttonInactive,
        color: brandColors.textInactive,
      },
    },
    MuiIconButton: {
      label: {
        color: brandColors.main,
      },
      root: {
        '&.Mui-disabled': {
          '& .MuiIconButton-label': {
            color: brandColors.textInactive,
          },
        },
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: brandColors.textMain,
      },
    },
    MuiPickersDay: {
      daySelected: {
        '& .MuiIconButton-label': {
          color: brandColors.clean,
        },
      },
      dayDisabled: {
        '& .MuiIconButton-label': {
          color: brandColors.textInactive,
        },
      },
    },
    MuiCardActionArea: {
      root: {
        '& .MuiTouchRipple-root': {
          color: brandColors.main,
        },
      },
      focusHighlight: {
        backgroundColor: brandColors.main,
      },
    },
    MuiTab: {
      textColorPrimary: {
        color: brandColors.main,
        fontFamily: 'Poppins',
        textTransform: 'capitalize',
        fontSize: '1rem',
        '&.Mui-selected': {
          color: brandColors.main,
        },
      },
    },
    MuiInput: {
      formControl: {
        color: brandColors.main,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 28,
        fontWeight: 700,
      },
      outlined: {
        height: 48,
        textTransform: 'capitalize',
        '&:hover': {
          opacity: 0.75,
          backgroundColor: brandColors.main,
          color: brandColors.clean,
          '& .MuiSvgIcon-root': {
            color: brandColors.clean,
          },
        },
        '&:active': {
          opacity: 0.75,
          backgroundColor: brandColors.main,
          color: brandColors.clean,
          '& .MuiSvgIcon-root': {
            color: brandColors.clean,
          },
        },
        '@media (hover:none)': {
          '&:hover': {
            opacity: 0.75,
            color: brandColors.main,
            backgroundColor: brandColors.clean,
          },
          '&:active': {
            opacity: 0.75,
            backgroundColor: brandColors.main,
            color: brandColors.clean,
          },
        },
      },
      contained: {
        height: 48,
        textTransform: 'capitalize',
        '&:hover': {
          color: brandColors.clean,
          backgroundColor: brandColors.main,
          opacity: 0.75,
          '& .MuiSvgIcon-root': {
            color: brandColors.clean,
          },
        },
        '&:active': {
          color: brandColors.clean,
          backgroundColor: brandColors.main,
          opacity: 0.75,
          '& .MuiSvgIcon-root': {
            color: brandColors.clean,
          },
        },
        '@media (hover:none)': {
          '&:hover': {
            color: brandColors.clean,
            backgroundColor: `${brandColors.main} !important`,
            opacity: 1,
          },
          '&:active': {
            backgroundColor: brandColors.main,
            color: brandColors.clean,
          },
        },
      },
    },
  }

  const brandAssets: ThemeBrandAssetsType = {
    pageBackgrounds: {
      main: productBackgroundsMap[productName].authDesktop,
      mainMobile: productBackgroundsMap[productName].authMobileBg,
      mainCard: productBackgroundsMap[productName].mainCard,
      secondary: {
        background: brandColors.background,
        backgroundSize: 'cover',
      },
      secondaryMobile: productBackgroundsMap[productName].secondaryMobile,
      secondaryCard: productBackgroundsMap[productName].secondaryCard,
      error: {
        background: brandColors.background,
        backgroundSize: 'cover',
      },
      errorCardMain: {
        background: `url(${errorCardBg}) no-repeat left top scroll`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      },
      errorCardMobileMain: {
        background: `url(${errorCardBg}) -20px 50px / cover no-repeat scroll !important`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      },
      loading: productBackgroundsMap[productName].loading,
      loadingMobile: productBackgroundsMap[productName].loadingMobile,
      review: productBackgroundsMap[productName].reviewBg,
      verify: null,
    },
    images: {
      changeVehicleOwnerPageCard: {
        background: `url(${contentCardRightSide}) no-repeat right center scroll`,
        backgroundSize: '50%, auto, cover',
      },
      logoDesktopHeader: {
        background: `url(${logoMin}) no-repeat left center scroll`,
        backgroundSize: 243,
        display: 'flex',
        flex: 1,
      },
      logoMobileHeader: {
        background: `url(${logoMin}) no-repeat 16px center scroll`,
        backgroundSize: 124,
        height: 60,
        margin: '-16px -16px 16px',
        backgroundColor: brandColors.main,
      },
      logoMax: {
        width: 309,
        margin: '40px 10px',
        alignSelf: 'end',
      },
    },
    layout: {
      LMBgHeaderContainer: {
        backgroundColor: brandColors.background,
      },
      ErrorPageCtaLink: {
        color: brandColors.main,
        '&:hover': {
          textDecoration: 'underline',
        },
      },
      StylesCommonCtaFooter: {
        '& > a': {
          color: brandColors.main,
          textDecoration: 'none',
        },
        '& > a:hover': {
          textDecoration: 'underline',
        },
      },
      AuthStrategyDisclaimer: {
        color: brandColors.textDisclaimer,
      },
      PolicyInsuranceDatesText: {
        color: brandColors.textPlaceholder,
      },
      PolicyCTAOverride: {
        color: brandColors.main,
      },
      ctaOverride: {
        textTransform: 'none',
      },
      policyListPlusButton: {
        textTransform: 'capitalize',
        color: brandColors.main,
      },
      card: {
        cursor: 'pointer',
        '&:hover p, &:visited p, &:active p': {
          color: brandColors.clean,
        },
        '&:hover, &:visited': {
          backgroundColor: brandColors.main,
        },
        '&:active': {
          backgroundColor: brandColors.main,
          opacity: 0.7,
        },
      },
      mobileButton: {
        borderRadius: 25,
        '& p': {
          color: brandColors.clean,
        },
        '&:hover': {
          opacity: '.75',
          background: brandColors.main,
        },
      },
      policiesListCardsPlusButtonContainer: {
        [theme.breakpoints.down('sm')]: {
          position: 'fixed',
          bottom: 0,
          width: '100%',
          padding: '30px 16px 12px !important',
          background: `linear-gradient(0deg, ${brandColors.background} 75%, rgba(255,255,255,0) 100%)`,
        },
      },
      mobileCustomHeadingText: {
        marginTop: 'auto',
        color: brandColors.main,
      },
      mobileCustomTitle: {
        fontSize: '28px',
      },
      mobileCustomSubTitle: {
        marginTop: 'auto',
        color: brandColors.headingMain,
      },
      LMBgHeaderHeader: {
        backgroundColor: brandColors.background,
        [theme.breakpoints.down('sm')]: {
          ...styleProductMap[productName].LMBgHeaderHeader,
        },
      },
      errorHeader: {
        width: '100%',
      },
      iconCustomColor: {
        color: brandColors.secondary,
      },
      myInsurances: {
        marginTop: '68px',
      },
      desktopHeaderBackground: {
        backgroundColor: brandColors.main,
        borderRadius: 0,
      },
      secondaryFontFamily: {
        fontFamily: 'Roboto !important',
      },
      nextBtn: {
        background: brandColors.main,
        color: brandColors.clean,
        border: `1px solid ${brandColors.main}`,
        '&:hover': {
          color: brandColors.clean,
          backgroundColor: `${brandColors.buttonActive} !important`,
          border: brandColors.textMain,
          opacity: 0.7,
        },
        textTransform: 'initial',
      },
      placeholderStyle: {
        color: brandColors.textPlaceholder,
        fontWeight: 400,
        fontStyle: 'normal',
      },
      customLabelRadio: {
        textTransform: 'none',
        overflow: 'hidden',
        '&:nth-child(1)': {
          borderRadius: '50px 0px 0px 50px',
        },
        '&:nth-child(2)': {
          borderRadius: '0px 50px 50px 0px',
        },
      },
      customInputRadio: {
        backgroundColor: 'none',
        '&:hover ': {
          color: brandColors.clean,
        },
      },
      customHeaderTextWrapper: {
        background: brandColors.background,
        padding: 16,
        zIndex: 10,
        marginTop: '0px',
      },
      LMBgHeaderPage: {
        marginTop: 0,
      },
      btnNext: {
        border: `1px solid ${brandColors.main}`,
        backgroundColor: brandColors.main,
        color: brandColors.clean,
        '&.MuiSvgIcon-root': {
          backgroundColor: 'transparent',
          color: brandColors.main,
        },
        '&#change-offer-button': {
          backgroundColor: brandColors.clean,
          color: brandColors.main,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          '&:hover': {
            backgroundColor: brandColors.buttonActive,
            color: brandColors.clean,
            '& svg': {
              fill: brandColors.clean,
            },
          },
          '@media (hover:none)': {
            '&:hover': {
              backgroundColor: brandColors.clean,
              color: brandColors.main,
            },
            '&:hover svg': {
              fill: brandColors.shadow + ' !important',
            },
            '&:active': {
              backgroundColor: brandColors.buttonActive,
              color: brandColors.clean,
              '& svg': {
                fill: brandColors.clean + ' !important',
              },
            },
          },
        },
      },
      cookiesCancelBtn: {
        opacity: 0.75,
        textTransform: 'none',
      },
      mileageNextBtn: {
        backgroundColor: brandColors.clean,
        color: brandColors.main,
        textTransform: 'none',
      },
      compareBtn: {
        backgroundColor: brandColors.inputActive,
        color: brandColors.main,
      },
      changeNextBtn: {
        backgroundColor: brandColors.clean,
        color: brandColors.main,
      },
      mobileCustomLogoWrapper: {
        background: brandColors.main,
        padding: 16,
        left: 0,
        right: 0,
        top: 0,
        position: 'absolute',
      },
      contactCustomerSwitchWrapper: {
        maxWidth: 400,
        marginBottom: 26,
      },
      customMobileSubmit: {
        [theme.breakpoints.down('sm')]: {
          position: 'absolute',
          bottom: 0,
        },
      },
      policySelect: {
        background: 'none',
        borderRadius: 0,
        boxShadow: 'none',
        color: brandColors.textPlaceholder,
      },
      offerItemVehicleCardHeaderContainerStyle: {
        '& h3': {
          fontWeight: 400,
          fontSize: 18,
          lineHeight: 1.25,
        },
      },
      policyCardV2CardHeaderContainer: {
        '& h3': {
          fontWeight: 400,
        },
      },
    },
  }

  return brandTheme.setup({
    productName,
    colors: brandColors,
    typography: brandTypography,
    assets: brandAssets,
    overrides,
  })
}

export default brandTheme.init(initCallback)
