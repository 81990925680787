import { Events, FSMCommonTransitions } from '../../../../../FSM/shared/constants'
import { mergeDeep } from '../../../../../service/util.service'
import actions from '../../../../../FSM/shared/actions'
import guards from '../../../../../FSM/shared/guards'
import sharedFSMconfig, { MachineConfigType } from '../../../../../FSM/shared/config'
import { AuthMethod } from '../../../../../constants/auth-constants'
import { isCriticalError } from '@dg-util'
import { BaseActionObject } from 'xstate'

const {
  AUTH,
  AUTH_CHOICE,
  AUTH_REGULAR,
  AUTH_AGENT,
  QB_REVIEW,
  QB_CHANGE,
  QB_VERIFY,
  QB_PURCHASED,
  QB_PURCHASED_FAILURE,
  QB_PURCHASED_DONE,
  QB_CHOOSE_ADDRESS,
  QB_FIND,
  ERROR,
} = FSMCommonTransitions

const FLOW_MACHINE_ID = 'QBFlowMachineContent'

const FSMConfig: MachineConfigType = {
  id: FLOW_MACHINE_ID,
  initial: AUTH,
  context: {
    authorized: false,
    authMethod: '',
  },
  states: {
    [AUTH]: {
      initial: AUTH_CHOICE,
      states: {
        [AUTH_CHOICE]: {
          on: {
            CUSTOM_AUTH_SUCCESS: {
              target: `#${FLOW_MACHINE_ID}.${QB_CHOOSE_ADDRESS}`,
              actions: ['setUserAuthorized', 'setVippsAuth', 'setBackPath'],
            },
            CUSTOM_AUTH_FAILURE: {},
            NEXT: AUTH_REGULAR,
            [Events.AGENT_AUTH]: AUTH_AGENT,
          },
        },
        [AUTH_REGULAR]: {
          on: {
            [Events.NEXT]: { target: `#${FLOW_MACHINE_ID}.${QB_FIND}`, actions: 'setBackPath' },
            [Events.BACK]: AUTH_CHOICE,
          },
          exit: ['setUserAuthorized', 'setRegularAuth'],
        },
        [AUTH_AGENT]: {
          on: {
            [Events.NEXT]: {
              target: `#${FLOW_MACHINE_ID}.${QB_FIND}`,
              actions: ['setUserAuthorized', 'setAgentAuth'],
            },
            [Events.BACK]: {
              actions: ['redirectToAgentPortal'],
            },
            [Events.AUTH_ERROR]: AUTH_AGENT,
          },
        },
      },
    },
    [QB_CHOOSE_ADDRESS]: {
      on: {
        NEXT: { target: QB_FIND, actions: 'setBackPath' },
        REVIEW: { target: QB_REVIEW, actions: 'setBackPath' },
        BACK: { target: `#${FLOW_MACHINE_ID}.${AUTH}`, actions: 'signOut' },
      },
    },
    [QB_FIND]: {
      on: {
        NEXT: { target: QB_REVIEW, actions: 'setBackPath' },
        BACK: [
          {
            target: QB_CHOOSE_ADDRESS,
            cond: 'isVippsAuth',
          },
          {
            target: `#${FLOW_MACHINE_ID}.${AUTH}`,
            actions: 'signOut',
            cond: 'isNotVippsAuth',
          },
          // Switch back to MyPages will happen on components page level
        ],
      },
    },
    [QB_REVIEW]: {
      on: {
        BACK: [
          {
            target: QB_CHOOSE_ADDRESS,
            cond: (ctx) => ctx.backPath === QB_CHOOSE_ADDRESS,
          },
          {
            target: QB_FIND,
            cond: (ctx) => {
              return ctx.backPath !== QB_CHOOSE_ADDRESS
            },
          },
        ],
        NEXT: QB_VERIFY,
        CHANGE: QB_CHANGE,
      },
    },
    [QB_CHANGE]: {
      on: {
        BACK: QB_REVIEW,
        NEXT: QB_REVIEW,
      },
    },
    [QB_VERIFY]: {
      on: {
        BACK: QB_REVIEW,
        PAYMENT_SUCCESS: QB_PURCHASED,
        PAYMENT_FAILURE: `${QB_PURCHASED}.${QB_PURCHASED_FAILURE}`,
        PAYMENT_CANCEL: QB_VERIFY,
      },
    },
    [QB_PURCHASED]: {
      initial: QB_PURCHASED_DONE,
      states: {
        [QB_PURCHASED_DONE]: {
          type: 'final',
        },
        [QB_PURCHASED_FAILURE]: {
          on: {
            BACK: {
              target: `#${FLOW_MACHINE_ID}.${AUTH}`,
              actions: ['signOut'],
            },
          },
        },
      },
    },
    [ERROR]: {
      on: {
        [Events.BACK]: [
          {
            target: QB_FIND,
            cond: (ctx, type, state) => {
              const errorType = (state.state.event as BaseActionObject)?.errorType
              const isCriticalErrorCode = isCriticalError(errorType?.code)

              return (
                ctx.backPath === QB_REVIEW &&
                ctx.authMethod !== AuthMethod.VIPPS &&
                !isCriticalErrorCode
              )
            },
          },
          {
            target: QB_CHOOSE_ADDRESS,
            cond: (ctx, type, state) => {
              const errorType = (state.state.event as BaseActionObject)?.errorType
              const isCriticalErrorCode = isCriticalError(errorType?.code)
              return (
                ctx.backPath === QB_REVIEW &&
                ctx.authMethod === AuthMethod.VIPPS &&
                !isCriticalErrorCode
              )
            },
          },
          {
            target: AUTH,
            actions: ['signOut'],
            cond: (ctx) => ctx.backPath === QB_REVIEW,
          },
        ],
      },
    },
  },
}

const FSMConfigContentNO = mergeDeep(FSMConfig, sharedFSMconfig)
const FSMOptionsContentNO = { guards, actions }

export { FSMConfigContentNO, FSMOptionsContentNO }
