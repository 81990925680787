const CONFIG = {
  EMAIL: 'privat@nordforsikring.no',
  ADVANCE_PURCHASE_INFO_LINK: '',
  SUPPORT_LINK: '',
  MAIN_LINK: {
    vehicle: 'https://www.nordforsikring.no',
    travel: 'https://www.nordforsikring.no',
    content: 'https://www.nordforsikring.no',
  },
  MYPAGES_LOGOUT_REDIRECT: {
    vehicle: 'https://www.nordforsikring.no/alle-produkter-2',
    travel: 'https://www.nordforsikring.no/alle-produkter-2',
    content: 'https://www.nordforsikring.no/alle-produkter-2',
  },
  INSURANCE: 'https://nordinsuretechgroup.se/forsikring',
  PRIVACY_POLICY_LINK: 'https://www.nordforsikring.no/personopplysninger',
  PARTNER_PRIVACY_POLICY_LINK: 'https://www.nordforsikring.no/personopplysninger',
  PARTNER_NAME: 'nord',
  PARTNER_NAME_AVTALEGIRO: 'Nord Forsikring',
  VIPPS_AUTH: true,
  TRANSLATION_LANGUAGES: ['no-NO'],
  GOOGLE_RECAPTCHA_LINKS: {
    PRIVACY: 'https://policies.google.com/privacy?hl=no',
    TERMS: 'https://policies.google.com/terms?hl=no',
  },
  FNOL: {
    PageWindshield: {
      iconLink: 'https://booking.bilglass.no/',
    },
    PageRoadsideAssistance: {
      iconLink: 'https://rsa2.falck.no/',
    },
  },
  REVIEW_LINK: 'https://nordinsuretechgroup.se/forsikring/nord-reiseforsikring',
  DOCUMENTS: {
    PRIVACY_STATEMENT: '',
    LP: {
      IPID: '',
      ANSVAR: '',
      DELKASKO: '',
      KASKO: '',
      SUPER: '',
    },
  },
}

export default CONFIG
